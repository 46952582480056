<template>
  <div class="indexBox">
    <div style="display: flex; background-color: #f7f8fa; width: 100%">
      <div
        style="
          background-color: #fff;
          padding-right: 10px;
          width: 100%;
          box-sizing: border-box;
        "
      >
        <!-- 上左 隐藏 -->
        <div v-if="false" style="width: 100%; display: flex">
          <el-date-picker
            v-model="form.StartTime"
            type="date"
            placeholder="开始日期"
          >
          </el-date-picker>

          <el-date-picker
            v-model="form.EndTime"
            type="date"
            placeholder="结束日期"
          >
          </el-date-picker>
          <el-select
            clearable
            v-model="form.Classtype"
            style="margin-left: 20px; width: 120px"
            placeholder="请选择分类名称"
          >
            <el-option
              v-for="item in tableData"
              :key="item.Id"
              :label="item.label"
              :value="item.Id"
            >
            </el-option>
          </el-select>

          <div
            style="
              width: 80px;
              height: 40px;
              text-align: center;
              line-height: 40px;
              background-color: #01b37c;
              color: #fff;
              border-radius: 5px;
              font-size: 16px;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="SearchFn"
          >
            搜索
          </div>

          <div
            style="
              width: 80px;
              height: 40px;
              text-align: center;
              line-height: 40px;
              background-color: #fff;
              color: #01b37c;
              border-radius: 5px;
              font-size: 16px;
              margin-left: 20px;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
            @click="ResetFn"
          >
            重置
          </div>
        </div>

        <div style="margin-bottom: 10px">
          <el-button
            :style="[
              { backgroundColor: nowChooseMonthIndex == 0 ? '#01b37c' : '' },
              { color: nowChooseMonthIndex == 0 ? '#fff' : '' },
            ]"
            @click="lastMonthFn"
            >上个月</el-button
          >
          <el-button
            :style="[
              { backgroundColor: nowChooseMonthIndex == 1 ? '#01b37c' : '' },
              { color: nowChooseMonthIndex == 1 ? '#fff' : '' },
            ]"
            @click="nowMonthFn"
            >当前月</el-button
          >
          <el-button
            :style="[
              { backgroundColor: nowChooseMonthIndex == 2 ? '#01b37c' : '' },
              { color: nowChooseMonthIndex == 2 ? '#fff' : '' },
            ]"
            @click="nextMonthFn"
            >下个月</el-button
          >
        </div>

        <div style="width: 100%">
          <!-- 当月日期表 -->
          <div style="width: 100%; display: flex; flex-wrap: wrap">
            <div
              @click="chooseToday(item)"
              v-for="(item, index) in everyDay"
              :key="index"
              :style="[
                { width: '100px' },
                { height: '60px' },
                {
                  backgroundColor:
                    index + 1 == nowToday ? '#EAF9F4' : '#f9f9f9',
                },
                { marginRight: '10px' },
                { marginBottom: '10px' },
                { textAlign: 'center' },
                { cursor: 'pointer' },
              ]"
            >
              <div style="margin-top: 10px">
                {{ item.month }} 月{{ item.day }}日
              </div>
              <div>星期{{ item.weekNum }}</div>
            </div>
          </div>
        </div>

        <!-- 排课 隐藏 -->
        <div
          v-if="false"
          style="
            width: 100%;
            background-color: #fff;
            display: flex;
            margin-top: 20px;
          "
        >
          <div style="width: 100%; margin-right: 0px; overflow-x: auto">
            <div
              style="
                width: 100%;
                max-width: 1476px;
                height: 80px;
                display: flex;
                align-items: center;
                background-color: #f5f5f5;
                justify-content: space-between;
              "
            >
              <div
                @click="clickWeekFn(index)"
                v-for="(item, index) in AllWeekData"
                :key="index"
                :style="[{ color: weekIndex == index ? '#333' : '#333' }]"
                style="text-align: center; cursor: pointer; width: 200px"
              >
                <div>{{ item.name }}</div>
                <div style="margin-top: 5px">{{ item.date }}</div>
              </div>
            </div>

            <div
              style="
                width: 100%;
                max-width: 1486px;
                display: flex;
                justify-content: space-between;
                overflow-x: auto;
              "
            >
              <div
                style="width: 100%"
                v-for="(item, index) in AllWeekData"
                :key="index"
              >
                <div
                  v-for="(val, index) in item.courseData"
                  class="coursebox"
                  :style="[
                    { width: '200px' },
                    {
                      backgroundColor:
                        val.myClassCheck == true ? '#EAF9F4' : '#f9f9f9',
                    },
                  ]"
                  :key="index"
                >
                  <div
                    style="
                      width: 100%;
                      text-align: start;
                      font-weight: bold;
                      margin-bottom: 6px;
                      padding: 0 10px;
                      box-sizing: border-box;
                    "
                  >
                    {{ val.StoreName }}
                  </div>

                  <div v-if="val.StartHour">
                    {{
                      val.StartHour < 10 ? "0" + val.StartHour : val.StartHour
                    }}:{{
                      val.StartMinute < 10
                        ? "0" + val.StartMinute
                        : val.StartMinute
                    }}
                    -
                    {{ val.EndHour < 10 ? "0" + val.EndHour : val.EndHour }}:{{
                      val.EndMinute < 10 ? "0" + val.EndMinute : val.EndMinute
                    }}
                  </div>
                  <div v-if="val.PlanDetailInfo" style="margin-top: 7px">
                    {{ val.PlanDetailInfo.FileName || "无" }}
                  </div>
                  <div style="margin-top: 10px">
                    {{ val.WorkName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 上左 -->
        <div style="width: 100%; display: flex; align-items: center">
          <div style="">开始：</div>
          <el-date-picker
            style="width: 150px"
            v-model="form.StartTime3"
            type="date"
            placeholder="开始日期"
          >
          </el-date-picker>

          <div style="margin-left: 10px">结束：</div>
          <el-date-picker
            style="margin: 0 0px; width: 150px"
            v-model="form.EndTime3"
            type="date"
            placeholder="结束日期"
          >
          </el-date-picker>

          <span style="margin-left: 10px">门店: </span>
          <el-select
            clearable
            filterable
            v-model="form.StroeId"
            style="margin-left: 10px"
            placeholder="请选择门店"
          >
            <el-option
              v-for="item in AllDoorData"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>

          <el-select
            clearable
            filterable
            v-model="form.TeachId"
            style="margin-left: 20px; width: 150px"
            placeholder="请选择教师"
          >
            <el-option
              v-for="item in AllTeacherData"
              :key="item.NickName"
              :label="item.NickName"
              :value="item.NickName"
            >
            </el-option>
          </el-select>

          <!-- <span style="margin-left: 20px;">教师: </span>

          <el-input style="width: 200px;margin-left: 10px" v-model="form.TeachId" placeholder="请输入教师" /> -->

          <!-- <el-select
            v-model="form.TeachId"
            style="margin-left: 10px"
            placeholder="请选择教师"
          >
            <el-option
              v-for="item in WorkTypeData"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select> -->

          <span style="margin-left: 20px">分类名称: </span>
          <el-select
            filterable
            clearable
            v-model="form.Classtype"
            style="margin-left: 20px; width: 140px"
            placeholder="请选择分类名称"
          >
            <el-option
              v-for="item in tableData"
              :key="item.Id"
              :label="item.label"
              :value="item.Id"
            >
            </el-option>
          </el-select>

          <div
            style="
              width: 80px;
              height: 40px;
              text-align: center;
              line-height: 40px;
              background-color: #01b37c;
              color: #fff;
              border-radius: 5px;
              font-size: 16px;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="SearchFn"
          >
            搜索
          </div>

          <div
            style="
              width: 80px;
              height: 40px;
              text-align: center;
              line-height: 40px;
              background-color: #fff;
              color: #01b37c;
              border-radius: 5px;
              font-size: 16px;
              margin-left: 20px;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
            @click="ResetFn"
          >
            重置
          </div>
        </div>

        <!-- 当天列表 -->
        <div style="margin-top: 20px">
          <el-table
            :data="ThisWeekData"
            :header-cell-style="getRowClass"
            style="width: 100%; background-color: #fff"
            :row-style="{ height: '60px' }"
          >
            <el-table-column label="序号" prop="CardId">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>

            <!-- <el-table-column label="课程名称" prop="WorkName" /> -->
            <el-table-column label="分类名称" prop="WorkTypeName">
              <!-- <template slot-scope="scope">
            <span v-if="scope.row.WorkType == 1">基础课</span>
            <span v-if="scope.row.WorkType == 2">专项课</span>
            <span v-if="scope.row.WorkType == 3">试听课</span>
          </template> -->
            </el-table-column>
            <!-- <el-table-column label="学生批次" prop="CardId" /> -->
            <el-table-column label="门店" prop="StoreName" />
            <el-table-column label="课室" prop="StoreRoomName" />
            <el-table-column label="教师名称" prop="TeacherName" />
            <el-table-column label="课程教案" prop="PlanDetailInfo.FileName" />
            <!-- <el-table-column label="课室" prop="CardNum" /> -->
            <el-table-column label="上课时间" prop="WorkTime" />
            <!-- <el-table-column label="课程状态" prop="Status">
          <template slot-scope="scope">
            <span v-if="scope.row.WorkType == 0">未审批</span>
            <span v-if="scope.row.WorkType == 1">已通过</span>
            <span v-if="scope.row.WorkType == 2">未通过</span>
          </template>
        </el-table-column> -->
            <el-table-column label="课程状态" prop="ClassStatus">
              <template slot-scope="scope">
                <span v-if="scope.row.ClassStatus == 0">未开课</span>
                <span v-if="scope.row.ClassStatus == 1">已开课</span>
                <span v-if="scope.row.ClassStatus == 2">已结束</span>
              </template>
            </el-table-column>
            <el-table-column label="报名学生" prop="StuNum" />
            <!-- <el-table-column label="审批状态" prop="Status">
          <template slot-scope="scope">
            <span v-if="scope.row.Status == 0">未审批</span>
            <span v-if="scope.row.Status == 1">已通过</span>
            <span v-if="scope.row.Status == 2">未通过</span>
          </template>
        </el-table-column> -->

            <!-- <el-table-column label="操作">
          <template #default="scope">
            <span @click="openEditPeoPleNum(scope.row)" style="font-size: 14px;color: #007AFF;cursor: pointer;">修改人数</span>
            <span v-if="scope.row.Status == 2" @click="openRejectTc(scope.row)" style="font-size: 14px;color: #EE6E6E;cursor: pointer;margin-left: 10px;">
              驳回理由
            </span>
          </template>
        </el-table-column> -->
          </el-table>
        </div>

        <!-- 课程统计 -->
        <div
          style="
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            background-color: #f7f8fa;
            margin-top: 30px;
          "
        >
          <div style="font-size: 18px; font-weight: bold">课程统计</div>

          <div
            style="
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div style="width: 50%">
              <div style="font-size: 14px; font-weight: bold">
                {{ form.TeachId != "" ? "涉及门店" : "未安排教师" }}
              </div>
              <div
                v-for="(item, index) in noClassTeacherStatisticsDOs"
                :key="index"
                style="
                  display: flex;
                  margin-top: 10px;
                  align-items: center;
                  flex-wrap: wrap;
                "
              >
                <div style="font-size: 14px; color: #666">
                  {{ item.StoreName }}：
                </div>
                <div
                  v-show="form.TeachId == ''"
                  v-for="(item2, index2) in item.teacherInfos || []"
                  :key="index2"
                  style="font-size: 14px; color: #333"
                >
                  {{ item2 + "、" || "无" }}
                </div>
                <div v-show="form.TeachId != ''">{{ item.Count }}</div>
              </div>
            </div>

            <div style="width: 50%">
              <!-- <el-color-picker v-model="color1"></el-color-picker> -->
              <div style="font-size: 14px; font-weight: bold">
                已排课程统计 （{{ allCountNum }}节）
              </div>
              <div v-for="(item, index3) in classStatisticsDOs" :key="index3">
                <div
                  v-if="item.TypeName !== null && item.TypeName !== ''"
                  style="display: flex; margin-top: 10px; align-items: center"
                >
                  <div style="font-size: 14px; color: #666">
                    <!-- {{ item.Year }}岁{{ item.Month }}月第{{ item.Week }}周： -->
                    {{ item.TypeName }}：
                  </div>
                  <div style="font-size: 14px; color: #333">
                    {{ item.Count }}节
                  </div>
                </div>
              </div>
            </div>

            <div v-if="false" style="width: 30%">
              <div style="font-size: 14px; font-weight: bold">已排课程统计</div>
              <div
                v-for="(item, index) in classTypeStatisticsDOs"
                :key="index"
                style="display: flex; margin-top: 10px; align-items: center"
              >
                <div
                  v-if="item.WorkType == 1"
                  style="font-size: 14px; color: #666"
                >
                  基础课：
                </div>
                <div
                  v-if="item.WorkType == 2"
                  style="font-size: 14px; color: #666"
                >
                  专项课：
                </div>
                <div
                  v-if="item.WorkType == 3"
                  style="font-size: 14px; color: #666"
                >
                  试听课：
                </div>
                <div
                  v-if="item.WorkType == 1"
                  style="font-size: 14px; color: #333"
                >
                  {{ item.Count }}
                </div>
                <div
                  v-if="item.WorkType == 2"
                  style="font-size: 14px; color: #333"
                >
                  {{ item.Count }}
                </div>
                <div
                  v-if="item.WorkType == 3"
                  style="font-size: 14px; color: #333"
                >
                  {{ item.Count }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 申请课程 -->
    <el-dialog
      v-if="false"
      title="申请课程"
      :visible.sync="dialogVisible1"
      width="500px"
    >
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <!-- 选择学生 -->
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择学生
            </div>
            <div style="flex: 1; display: flex; justify-content: space-between">
              <el-select
                clearable
                v-model="form.Year"
                style="width: 32%"
                placeholder="选择岁"
              >
                <el-option
                  v-for="item in YearData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
              <el-select
                clearable
                v-model="form.Month"
                style="width: 32%"
                placeholder="选择月"
              >
                <el-option
                  v-for="item in MonthData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
              <el-select
                clearable
                v-model="form.Week"
                style="width: 32%"
                placeholder="选择周"
              >
                <el-option
                  v-for="item in WeekData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <!-- 学生人数 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              学生人数
            </div>
            <el-input
              style="flex: 1"
              v-model="form.StuNum"
              placeholder="请输入学生人数"
            />
          </div>

          <!-- 选择日期 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择日期
            </div>
            <el-date-picker
              style="flex: 1"
              v-model="form.WorkTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>

          <!-- 选择时间 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择时间
            </div>
            <el-time-select
              style="flex: 1"
              v-model="form.Time"
              :picker-options="{
                start: '09:00',
                step: '00:15',
                end: '22:00',
              }"
              placeholder="选择时间"
            >
            </el-time-select>
            <span style="margin: 0 5px">至</span>
            <el-time-select
              style="flex: 1"
              v-model="form.Time2"
              :picker-options="{
                start: '09:00',
                step: '00:15',
                end: '22:00',
              }"
              placeholder="选择时间"
            >
            </el-time-select>
          </div>

          <!-- 选择课室 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择课室
            </div>
            <el-select
              clearable
              v-model="form.StoreRoomName"
              style="flex: 1"
              placeholder="请选择课室"
            >
              <el-option
                v-for="item in StoreData"
                :key="item.Name"
                :label="item.Name"
                :value="item.Name"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 选择课程 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择课程
            </div>
            <el-select
              clearable
              v-model="form.WorkId"
              style="flex: 1"
              placeholder="请选择课程"
            >
              <el-option
                v-for="item in courseData"
                :key="item.Id"
                :label="item.WorkName + '(' + item.TeachPlanName + ')'"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 分类名称 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择类型
            </div>
            <el-select
              clearable
              v-model="form.WorkType"
              style="flex: 1"
              placeholder="请选择类型"
            >
              <el-option
                v-for="item in WorkTypeData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 选择教案 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择教案
            </div>
            <el-select
              clearable
              v-model="form.BigTeachPlanId"
              style="flex: 1"
              placeholder="请选择教案"
            >
              <el-option
                v-for="item in teachData"
                :key="item.Id"
                :label="item.TeachPlanName"
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <span
              @click="SearchSmallIdFn"
              style="margin-left: 5px; color: #01b37c; cursor: pointer"
              >查询</span
            >
          </div>

          <!-- 选择小教案 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择小教案
            </div>
            <el-select
              clearable
              v-model="form.TeachPlanId"
              style="flex: 1"
              placeholder="请选择教案"
            >
              <el-option
                v-for="item in SmallTeachData"
                :key="item.Id"
                :label="item.FileName"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            @click="offDialogVisible1"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="AddExamineFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetAllTeachPlan,
  GetAllUser,
  GetAllUserName,
  GetAllWork,
  AddExamine,
  GetAllClassMeByTime,
  GetAllClassByTime, //这一周
  GetAllClassByStartTime,
  GetOwnStore,
  ClassStatistics, //课程统计
  GetAllStore,
} from "@/request/api.js";

export default {
  inject: ["reload"],
  data() {
    return {
      color1: "#999999",
      nowChooseMonthIndex: 1,
      tableData: [],
      teachData: [],
      AllTeacherData: [],

      //打印测试
      printUrl: "https://lupic.cdn.bcebos.com/20210629/9908923_14.jpg",
      printUrl2: "https://lupic.cdn.bcebos.com/20210629/9908923_14.jpg",
      someJSONdata: [
        {
          name: "John Doe",
          email: "john@doe.com",
          phone: "111-111-1111",
        },
        {
          name: "Barry Allen",
          email: "barry@flash.com",
          phone: "222-222-2222",
        },
        {
          name: "Cool Dude",
          email: "cool@dude.com",
          phone: "333-333-3333",
        },
      ],

      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      weekIndex: -1,
      AllWeekData: [
        // {
        //   name: "时间",
        //   Id: 0,
        //   date: "",
        // },
        {
          name: "星期一",
          Id: 1,
          date: "2023-03-01",
          courseData: [],
        },
        {
          name: "星期二",
          Id: 2,
          date: "2023-03-01",
          courseData: [],
        },
        {
          name: "星期三",
          Id: 3,
          date: "2023-03-01",
          courseData: [],
        },
        {
          name: "星期四",
          Id: 4,
          date: "2023-03-01",
          courseData: [],
        },
        {
          name: "星期五",
          Id: 5,
          date: "2023-03-01",
          courseData: [],
        },
        {
          name: "星期六",
          Id: 6,
          date: "2023-03-01",
          courseData: [],
        },
        {
          name: "星期日",
          Id: 7,
          date: "2023-03-07",
          courseData: [],
        },
      ],

      YearData: [], //年份
      MonthData: [], //月份
      WeekData: [
        {
          Id: 1,
          Name: "第一周",
        },
        {
          Id: 2,
          Name: "第二周",
        },
        {
          Id: 3,
          Name: "第三周",
        },
        {
          Id: 4,
          Name: "第四周",
        },
      ],
      dialogVisible1: false, //添加课程
      form: {
        Year: "",
        Month: "",
        WorkTime: "",
        Date: "",
        Time: "",
        TeachPlanId: "",
        WorkId: "",
        Time2: "",
        StartTime: "",
        EndTime: "",
        StartTime3: "",
        EndTime3: "",
        monday: "",
        sunday: "",
        StoreRoomName: "",
        Classtype: "",
        TeachId: "",
        StroeId: "",
      },
      courseData: [], //课程数据
      WorkTypeData: [
        {
          Id: 1,
          Name: "基础课",
        },
        {
          Id: 2,
          Name: "专项课",
        },
        {
          Id: 3,
          Name: "试听课",
        },
      ],
      SmallTeachData: [], //小教案数据
      MineWeekData: [],
      ThisWeekData: [], //本周课程
      dates: [], //日期
      pageIndex: 1,
      pageSize: 10,
      AllCount: 0,
      StoreData: [], //门店课室
      allCountNum: 0,
      noClassTeacherStatisticsDOs: [], //未排课教师
      classStatisticsDOs: [], //课程统计
      classTypeStatisticsDOs: [], //课程统计
      searchCheck: false,

      month: "",
      nowToday: "",
      everyDay: [],
      AllDoorData: [], //所有门店
    };
  },

  created() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    this.getNum(year, month);
    // let num = new Date(year, month, 0).getDate(); //返回这个月的总天数
    // console.log("num==>?", num);
    let that = this;
    this.GetAllUserNameFn();
    // this.GetAllUserFn();//获取教室列表
    this.GetAllTeachPlanFn(); //获取所有分类
    // this.GetAllWorkFn(); //获取所有课程
    // this.GetAllClassMeByTimeFn(); //获取自己一周要上的课程
    // this.GetAllClassByTimeFn(); //获取这一周课程
    // this.GetOwnStoreFn();//获取门店课室
    this.ClassStatisticsFn(); //获取课程统计

    let arr = [];
    let arr2 = [];
    for (let i = 1; i < 100; i++) {
      arr.push({
        Id: i,
        Name: i + "岁",
      });
    }
    for (let i = 1; i < 13; i++) {
      arr2.push({
        Id: i,
        Name: i + "月",
      });
    }
    // this.YearData = arr;//注释
    // this.MonthData = arr2;//注释
    // this.getWeektime3();//注释

    //date 你想要传的日期,格式为YYYY-MM-DD
    // let today = new Date();//注释
    // this.weeks(today);//注释
  },

  mounted() {
    let date = new Date();
    let month = date.getMonth();
    let nowToday = date.getDate(); //
    // console.log("nowToday======>", nowToday);//注释
    this.nowToday = nowToday;
    // this.getEveryDay(date, month);
    this.month = month + 1;
    this.GetAllClassByTimeFn({ month: month + 1, day: nowToday, weekNum: "" });
    this.GetAllStoreFn();
  },

  watch: {
    //监听
    "form.StartTime"(val) {
      console.log("val", val);
      // this.form.StartTime = "";
      let today = new Date(val);
      this.weeks(today);
      this.getWeektime4();
    },
    "form.EndTime"(val) {
      console.log("val", val);
      // this.form.EndTime = "";
      let today = new Date(val);
      this.weeks(today);
      this.getWeektime5();
    },

    "form.StartTime3"() {
      this.GetAllClassByStartTimeFn();
      this.ClassStatisticsFn();
    },
    "form.EndTime3"() {
      this.GetAllClassByStartTimeFn();
      this.ClassStatisticsFn();
    },

    "form.StroeId"() {
      // this.GetAllClassByTimeFn();
      this.GetAllClassByStartTimeFn();
      this.ClassStatisticsFn();
    },
    "form.TeachId"(e) {
      // this.GetAllClassByTimeFn();
      this.GetAllClassByStartTimeFn();
      this.ClassStatisticsFn();
    },
    "form.Classtype"(e) {
      this.form.Classtype = e;
      // this.GetAllClassByTimeFn();
      this.GetAllClassByStartTimeFn();
      this.ClassStatisticsFn();
    },
  },

  methods: {
    getNum(year, month) {
      let dates = [];
      const days = new Date(year, month, 0).getDate(); //返回这个月的总天数
      const weekDays = ["日", "一", "二", "三", "四", "五", "六"];
      for (let i = 1; i <= days; i++) {
        dates.push({
          // week: '星期'+ weekDays[new Date(year+'/'+month+'/'+`${i}`).getDay()],
          day: i,
          month: month,
          weekNum:
            weekDays[new Date(year + "/" + month + "/" + `${i}`).getDay()],
        });
      }
      console.log("新dates==>", dates);
      this.everyDay = dates;
      return dates;
    },

    lastMonthFn() {
      this.nowChooseMonthIndex = 0;
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth();
      this.month = month;
      this.getNum(year, month);
      this.GetAllClassByTimeFn();
    },
    nowMonthFn() {
      this.nowChooseMonthIndex = 1;
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      this.month = month;
      this.getNum(year, month);
      this.GetAllClassByTimeFn();
    },
    nextMonthFn() {
      this.nowChooseMonthIndex = 2;
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 2;
      this.month = month;
      this.getNum(year, month);
      this.GetAllClassByTimeFn();
    },

    //获取所有分类
    GetAllTeachPlanFn() {
      let that = this;
      GetAllTeachPlan().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有教案分类==>", res.Data);
          // that.teachData = res.Data;
          that.tableData = res.Data;
        }
      });
    },

    // 获取教师列表
    GetAllUserNameFn() {
      let that = this;
      GetAllUserName().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取教师列表==>", res.Data);

          that.AllTeacherData = res.Data;
        } else {
          this.$message.error(res.Message);
        }
      });
    },

    monthChange(date) {
      this.getEveryDay(date, date.getMonth());
    },
    // 获取当月每一天
    getEveryDay(date, month) {
      console.log("date", date);
      //设置月份
      date.setMonth(month + 1);
      //设置一个月的某一天-这里设置为零则取到的日期中的天就会是当月的最后一天（比如，二月份就是28或29，其他月份就是30或31），方便下边的循环
      date.setDate(0);
      console.log("date.setDate(0);==>", date.setDate(0));
      let dayArry = [];
      //获取月份的某一天
      let day = date.getDate();
      console.log("day==>", day);
      for (let i = 1; i <= day; i++) {
        date.setDate(i); //如果只获取当前选择月份的每一天，则不需要date.setDate(i)只需dayArry.push(i)即可，其中date.setDate(i)是设置当前月份的每一天
        dayArry.push(
          // i + ' ' + this.getWeekday(date.getDay())
          {
            day: i,
            month: month + 1,
            weekNum: this.getWeekday(date.getDay()),
          }
        ); //选中月份的每一天和当天是星期几
      }
      this.everyDay = dayArry;
      console.log("dayArry==>", dayArry);
    },
    getWeekday(day) {
      return ["日", "一", "二", "三", "四", "五", "六"][day];
    },

    // 选择某一天课程
    chooseToday(row) {
      console.log("当前==>", row);
      this.nowToday = row.day;
      this.GetAllClassByTimeFn(row);
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      // this.GetAllStoreFn(); //
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageIndex = val;
      // this.GetAllStoreFn(); //
    },

    getWeektime1() {
      let myDate = new Date(); // 获取今天日期
      const dateArray = [];
      for (let i = 0; i < 7; i++) {
        dateArray.push(myDate.toLocaleDateString());
        myDate.setDate(myDate.getDate() - 1);
      }
      console.log("dateArray==>", dateArray);
      return dateArray;
    },

    getWeektime2() {
      let myDate = new Date();
      myDate.setDate(myDate.getDate() - 6);
      const dateArray = [];
      for (let i = 0; i < 7; i++) {
        dateArray.push(myDate.toLocaleDateString());
        myDate.setDate(myDate.getDate() + 1);
      }
      console.log("dateArray2==>", dateArray);
      return dateArray;
    },

    // 默认 未搜索时的时间
    getWeektime3() {
      let monday = new Date();
      let sunday = new Date();
      const nowDay = new Date().getDay() || 7;
      monday.setDate(monday.getDate() - nowDay + 1);
      sunday.setDate(sunday.getDate() - nowDay + 7);
      console.log("monday==>", monday.toLocaleDateString());
      console.log("sunday==>", sunday.toLocaleDateString());
      this.form.monday = monday.toLocaleDateString();
      this.form.sunday = sunday.toLocaleDateString();
      return {
        monday: monday.toLocaleDateString(),
        sunday: sunday.toLocaleDateString(),
      };
    },

    // 搜索时的时间 开始
    getWeektime4() {
      console.log("选择开始");
      let monday = new Date(this.form.StartTime);
      let sunday = new Date(this.form.StartTime);
      const nowDay = new Date(this.form.StartTime).getDay() || 7;
      monday.setDate(monday.getDate() - nowDay + 1);
      sunday.setDate(sunday.getDate() - nowDay + 7);
      console.log("monday==>", monday.toLocaleDateString());
      console.log("sunday==>", sunday.toLocaleDateString());
      this.form.monday = monday.toLocaleDateString();
      this.form.sunday = sunday.toLocaleDateString();
      return {
        monday: monday.toLocaleDateString(),
        sunday: sunday.toLocaleDateString(),
      };
    },

    // 搜索时的时间 结束
    getWeektime5() {
      console.log("选择结束");
      let monday = new Date(this.form.EndTime);
      let sunday = new Date(this.form.EndTime);
      const nowDay = new Date(this.form.EndTime).getDay() || 7;
      monday.setDate(monday.getDate() - nowDay + 1);
      sunday.setDate(sunday.getDate() - nowDay + 7);
      console.log("monday==>", monday.toLocaleDateString());
      console.log("sunday==>", sunday.toLocaleDateString());
      this.form.monday = monday.toLocaleDateString();
      this.form.sunday = sunday.toLocaleDateString();
      return {
        monday: monday.toLocaleDateString(),
        sunday: sunday.toLocaleDateString(),
      };
    },

    weeks(currentTime) {
      var currentDate = new Date(currentTime);
      var timesStamp = currentDate.getTime();
      var currenDay = currentDate.getDay();
      // console.log("currenDay==>", currenDay);
      var dates = [];
      for (var i = 0; i < 7; i++) {
        dates.push(
          new Date(
            timesStamp + 24 * 60 * 60 * 1000 * (i - ((currenDay + 6) % 7))
          )
            .toLocaleDateString()
            .replace(/\//g, "-")
        );
      }
      console.log("dates==>", dates);
      this.form.StartTime = dates[0];
      this.form.EndTime = dates[6];
      this.dates = dates;
      let arr = this.AllWeekData;
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        element.date = dates[i];
        // console.log("element.date==>", new Date(dates[i]).getTime());
      }
      console.log("arr==>", arr);
      this.AllWeekData = arr;
    },

    //  // 获取所有门店
    GetAllStoreFn() {
      let that = this;
      GetAllStore().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有门店==>", res.Data);
          that.AllDoorData = res.Data;
        } else {
          this.$message.error(res.Message);
        }
      });
    },

    // 搜索
    SearchFn() {
      this.searchCheck = true;
      // this.GetAllClassByTimeFn();
      this.GetAllClassByStartTimeFn();
      this.ClassStatisticsFn();
    },
    ResetFn() {
      this.searchCheck = false;
      let today = new Date();
      this.weeks(today);
      this.getWeektime3();
      // this.GetAllClassByTimeFn();
      let date = new Date();
      let month = date.getMonth();
      let nowToday = date.getDate();
      this.nowToday = nowToday;
      this.getEveryDay(date, month);
      this.month = month + 1;
      // this.GetAllClassByTimeFn({month:month+1,day:nowToday,weekNum:''});
      this.GetAllClassByStartTimeFn({
        month: month + 1,
        day: nowToday,
        weekNum: "",
      });
      this.ClassStatisticsFn();
      this.form.Classtype = "";
      this.form.StroeId = "";
      this.form.TeachId = "";
    },

    // 表头
    getRowClass({ row, column, rowIndex, columnIndex }) {
      return "background:#F8FCFE;color:#fff;font-size:16px;color:#333";
    },

    //打开添加课程
    openDialogVisible1() {
      this.dialogVisible1 = true;
    },
    //关闭添加课程
    offDialogVisible1() {
      this.dialogVisible1 = false;
    },

    //选择星期
    clickWeekFn(e) {
      this.weekIndex = e;
    },

    //查询小教案
    SearchSmallIdFn() {
      let that = this;
      let index = that.teachData.findIndex((item) => {
        return item.Id == that.form.BigTeachPlanId;
      });
      this.SmallTeachData = that.teachData[index].teachPlans;
    },

    //教师自己申请课程
    AddExamineFn() {
      let that = this;
      console.log("教师自己申请课程==>", that.form);
      let StartHour = that.form.Time.split(":")[0];
      let StartMinute = that.form.Time.split(":")[1];
      let EndHour = that.form.Time2.split(":")[0];
      let EndMinute = that.form.Time2.split(":")[1];
      //加八个小时
      let miao = new Date(that.form.WorkTime).getTime() + 8 * 60 * 60 * 1000;
      console.log("miao==>", new Date(miao));
      // 转为时间
      that.form.WorkTime = new Date(miao);
      // return
      AddExamine({
        StartHour: StartHour,
        StartMinute: StartMinute,
        EndHour: EndHour,
        EndMinute: EndMinute,
        WorkType: that.form.WorkType,
        Year: that.form.Year,
        Month: that.form.Month,
        Week: that.form.Week,
        // WorkTime: that.form.WorkTime,
        WorkTime: new Date(miao),
        WorkId: that.form.WorkId == "" ? "-1" : that.form.WorkId,
        StuNum: that.form.StuNum,
        TeachPlanId: that.form.TeachPlanId,
        StoreRoomName: that.form.StoreRoomName,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("教师自己申请课程==>", res.Data);
          that.$message({
            message: "已提交申请",
            type: "success",
          });
          that.dialogVisible1 = false;
          that.form.Year = "";
          that.form.Month = "";
          that.form.Week = "";
          that.form.WorkTime = "";
          that.form.WorkType = "";
          that.form.Date = "";
          that.form.Time = "";
          that.form.TeachPlanId = "";
          that.form.BigTeachPlanId = "";
          that.form.WorkId = "";
          that.form.Time2 = "";
          that.form.StoreRoomName = "";
          that.GetAllClassMeByTimeFn(); //获取自己一周要上的课程
        } else {
          that.$message({
            message: res.Message,
            type: "error",
          });
        }
      });
    },

    //获取所有教案 wuyong
    GetAllTeachPlanFn2() {
      let that = this;
      GetAllTeachPlan().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有教案==>", res.Data);
          for (let i = 0; i < res.Data.length; i++) {
            const element = res.Data[i];
            element.TeachPlanName =
              element.Year + "岁" + element.Month + "月" + element.Week + "周";
          }
          that.teachData = res.Data;
        }
      });
    },

    //获取所有课程
    GetAllWorkFn() {
      let that = this;
      GetAllWork().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有课程==>", res.Data);
          that.courseData = res.Data;
        }
      });
    },

    //获取所有门店课室
    GetOwnStoreFn() {
      let that = this;
      GetOwnStore().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取门店==>", res.Data);
          that.StoreData = res.Data;
        }
      });
    },

    //获取课程统计
    ClassStatisticsFn() {
      let that = this;
      ClassStatistics({
        useStart: that.form.StartTime3 === "" ? false : true,
        useEndTime: that.form.EndTime3 === "" ? false : true,
        StartTime:
          that.form.StartTime3 == ""
            ? new Date("2001-1-1")
            : that.form.StartTime3,
        EndTime:
          that.form.EndTime3 == "" ? new Date("2001-1-1") : that.form.EndTime3,
        Classtype: that.form.Classtype == "" ? "-1" : that.form.Classtype,
        TeacherName: that.form.TeachId == "" ? "" : that.form.TeachId,
        storeId: that.form.StroeId == "" ? -1 : that.form.StroeId,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取课程统计==>", res.Data);
          // that.StoreData = res.Data;
          that.noClassTeacherStatisticsDOs =
            res.Data.noClassTeacherStatisticsDOs;
          that.classStatisticsDOs = res.Data.classStatisticsDOs;
          that.classTypeStatisticsDOs = res.Data.classTypeStatisticsDOs;
          that.allCountNum = res.Data.allCount || 0;
        }
      });
    },

    //获取一周的课程  用
    GetAllClassByTimeFn(row) {
      let that = this;
      let time =
        new Date().getFullYear() + "-" + that.month + "-" + that.nowToday;

      GetAllClassByTime({
        SearchTime: time,
        Classtype: that.form.Classtype == "" ? -1 : that.form.Classtype,
        TeacherName: that.form.TeachId,
        storeId: that.form.StroeId == "" ? -1 : that.form.StroeId,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取这一周课程==>", res.Data);

          for (let i = 0; i < res.Data.length; i++) {
            const element = res.Data[i];
            element.WorkTime =
              element.WorkTime.split(" ")[0] +
              " " +
              String(
                String(
                  element.StartHour < 10
                    ? "0" + element.StartHour
                    : element.StartHour
                ) +
                  ":" +
                  String(
                    element.StartMinute < 10
                      ? "0" + element.StartMinute
                      : element.StartMinute
                  ) +
                  "-" +
                  String(
                    element.EndHour < 10
                      ? "0" + element.EndHour
                      : element.EndHour
                  ) +
                  ":" +
                  String(
                    element.EndMinute < 10
                      ? "0" + element.EndMinute
                      : element.EndMinute
                  )
              );
          }

          that.ThisWeekData = res.Data;

          // if(res.Data.length==0){
          // console.log("StartTime==>", that.form.StartTime);
          // let today = new Date(that.form.StartTime);
          // that.weeks(today);
          // for (let h = 0; h < that.AllWeekData.length; h++) {
          //   const element = that.AllWeekData[h];
          //   element.courseData = [];
          // }
          // }
        }
      });
    },

    //获取某个时间段的课程  用
    GetAllClassByStartTimeFn(row) {
      let that = this;
      let time =
        new Date().getFullYear() + "-" + that.month + "-" + that.nowToday;

      GetAllClassByStartTime({
        useStart: that.form.StartTime3 === "" ? false : true,
        useEndTime: that.form.EndTime3 === "" ? false : true,
        StartTime:
          that.form.StartTime3 == "" ? new Date() : that.form.StartTime3,
        EndTime: that.form.EndTime3 == "" ? new Date() : that.form.EndTime3,
        Classtype: that.form.Classtype == "" ? -1 : that.form.Classtype,
        TeacherName: that.form.TeachId == "" ? "" : that.form.TeachId,
        storeId: that.form.StroeId == "" ? -1 : that.form.StroeId,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取这一周课程==>", res.Data);

          console.log("res.Data.length==>", res.Data.length);
          // return
          for (let i = 0; i < res.Data.length; i++) {
            const element = res.Data[i];
            element.WorkTime =
              element.WorkTime.split(" ")[0] +
              " " +
              String(
                String(
                  element.StartHour < 10
                    ? "0" + element.StartHour
                    : element.StartHour
                ) +
                  ":" +
                  String(
                    element.StartMinute < 10
                      ? "0" + element.StartMinute
                      : element.StartMinute
                  ) +
                  "-" +
                  String(
                    element.EndHour < 10
                      ? "0" + element.EndHour
                      : element.EndHour
                  ) +
                  ":" +
                  String(
                    element.EndMinute < 10
                      ? "0" + element.EndMinute
                      : element.EndMinute
                  )
              );
          }

          that.ThisWeekData = res.Data;
        }
      });
    },

    //获取一周的课程 前版本
    GetAllClassByTimeFn2() {
      let that = this;

      if (that.searchCheck == true) {
        that.getWeektime4();
        let today = new Date(that.form.StartTime);
        that.weeks(today);
      } else {
        let monday = new Date();
        let sunday = new Date();
        const nowDay = new Date().getDay() || 7;
        monday.setDate(monday.getDate() - nowDay + 1);
        sunday.setDate(sunday.getDate() - nowDay + 7);
        console.log("monday==>", monday.toLocaleDateString());
        console.log("sunday==>", sunday.toLocaleDateString());
        this.form.monday = monday.toLocaleDateString();
        this.form.sunday = sunday.toLocaleDateString();
      }
      GetAllClassByTime({
        StartTime: that.form.monday,
        EndTime: that.form.sunday,
        Classtype: that.form.Classtype == "" ? -1 : that.form.Classtype,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取这一周课程==>", res.Data);
          that.ThisWeekData = res.Data;

          // if(res.Data.length==0){
          console.log("StartTime==>", that.form.StartTime);
          let today = new Date(that.form.StartTime);
          that.weeks(today);
          for (let h = 0; h < that.AllWeekData.length; h++) {
            const element = that.AllWeekData[h];
            element.courseData = [];
          }
          // }

          console.log("that.dates==>", that.dates);
          for (let i = 0; i < res.Data.length; i++) {
            const element = res.Data[i];

            element.myClassCheck = false; //是否是自己的课

            // 只取年月日
            // let time = element.WorkTime = element.WorkTime.split(" ")[0];
            //每一个课程的时间戳
            let time = new Date(element.WorkTime.split(" ")[0]).getTime();

            // 一周每天的时间戳
            for (let j = 0; j < that.dates.length; j++) {
              const element2 = that.dates[j];

              //一天的时间戳是多少
              let dayTime = new Date(element2).getTime() + 86400000;

              if (time > new Date(element2).getTime() && time < dayTime) {
                //根据that.AllWeekData[j].courseData中的StartHour进行从小到大排序
                that.AllWeekData[j].courseData.push(element);
                that.AllWeekData[j].courseData.sort(function (a, b) {
                  console.log("a==>", a.StartHour, "b====>", b.StartHour);
                  return a.StartHour - b.StartHour;
                });
              }
              // else{
              //   that.AllWeekData[j].courseData.push({});
              // }
            }
          }
        }
      });
    },

    //获取自己一周要上的课程
    GetAllClassMeByTimeFn() {
      let that = this;

      let monday = new Date();
      let sunday = new Date();
      const nowDay = new Date().getDay() || 7;
      monday.setDate(monday.getDate() - nowDay + 1);
      sunday.setDate(sunday.getDate() - nowDay + 7);
      console.log("monday==>", monday.toLocaleDateString());
      console.log("sunday==>", sunday.toLocaleDateString());
      this.form.monday = monday.toLocaleDateString();
      this.form.sunday = sunday.toLocaleDateString();

      GetAllClassMeByTime({
        StartTime: that.form.monday,
        EndTime: that.form.sunday,
        Classtype: that.form.Classtype == "" ? -1 : that.form.Classtype,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取自己一周要上的课程==>", res.Data);
          that.MineWeekData = res.Data;
          that.AllCount = res.Data.length;

          console.log("MineWeekData==>", that.MineWeekData);
        }
      });
    },

    // 打印测试
    // printHTML() {
    //   this.$print({
    //   	printable: 'printBox',
    //     type: 'html',
    //     header: '打印标题',
    //     targetStyles: ['*'], // 打印内容使用所有HTML样式，没有设置这个属性/值，设置分页打印没有效果
    //   })
    // },
    // printJSON() {
    //   this.$print({printable: this.someJSONdata, properties: ['name', 'email', 'phone'], type: 'json'})
    // },
    // printPDF() {
    //   this.$print({printable: 'docs/xx_large_printjs.pdf', type: 'pdf'})
    // },
    // printImg() {
    //   this.$print({
    //   	printable: this.printUrl, // 也可设置清晰度比html绑定更高精度的图片
    //     type: 'image'
    //   });
    //   this.$print({
    //   	printable: [this.printUrl, this.printUrl2], // 打印多张图片
    //     type: 'image'
    //   });
    // },

    // vnc token
    copyVNCToken() {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute("value", this.VNCToken);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.CopySuccessMessage();
    },
    // token
    copyToken() {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute("value", this.token);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.CopySuccessMessage();
    },

    CopySuccessMessage() {
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    SuccessMessage() {
      this.$message({
        message: "刷新成功",
        type: "success",
      });
    },
    ErrorMessage() {
      this.$message({
        message: "刷新失败",
        type: "error",
      });
    },
  },
};
</script>

<style lang="less" scoped>
//主盒子
.indexBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

// .课程表
.coursebox {
  // width: 130px;
  height: 120px;
  text-align: center;
  // padding: 20px 10px 0 10px;
  padding: 10px 0px 0 0px;
  box-sizing: border-box;
  // margin-left: 10px;
  margin-right: 0px;
  margin-top: 10px;
  background-color: #f9f9f9;
}

//小格子
.box-property {
  width: 280px;
  height: 70px;
  box-shadow: 0px 2px 2px #d0d0d0;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

//图片属性
.img-property {
  width: 70px;
  float: left;
}

//文字属性
.text-property {
  font-size: 20px;
  margin-left: 30px;
}

//数据文字属性
.text-data {
  font-size: 20px;
  margin-left: 40px;
  color: dodgerblue;
}

/* 鼠标移入表格不高亮 */

.table-tranparent.el-table tbody tr:hover > td {
  background-color: #ffffff !important;
}

.table-tranparent.el-table tbody tr:hover {
  background-color: #ffffff !important;
}
</style>
